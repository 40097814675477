.contentContainer {


  background-color: #fff;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
  float: left;


}

.boxContainer {
  width: 50%;
  padding: 5px;
  margin: 0 auto;
  text-align: left;

  @media (max-width: 768px) {
    width: 95%;


  }
}

.messageBox {
  width: 100%;
  height: 150px;
  resize: none;
  border: none;
  font-size: 14px;
  padding-top: 30px;
  padding-left: 20px;
  border: 1px solid #c3c6d0;
  margin: 0px;
  border-radius: 5px;

  @media (max-width: 768px) {
    height: 100px;


  }

}

.messageBox:focus {
  outline: none;
}

.postButton {
  margin: 0;
  margin-top: 20px;
  border: none;
  float: right;
  color: #FFFFFF;
  display: block;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3px;
  padding: 10px 50px;

  background-color: #58a58f;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  @media (max-width: 768px) {
    padding: 10px 10px;


  }
}

.postButton:hover {
  outline: none;
  background-color: #000;

}