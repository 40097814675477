.event-card {
    background-color:rgb(255, 255, 255); 
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    width: 50%;
    margin-left: 25%;
    border: 1px solid #58a58f; 
    float: left;
  @media (max-width: 1200px) {
    width: 95%;
    margin-left: 2.5%;
  } }
  .Event-card:hover {  background-color: #eefbf2;  }


  .event-date1 {
    background-color: #58a58f;
    margin-top:  0px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    float: left;
    width: 20%;
    height: 100%;
    text-align: center;
    border-right: 1px solid #58a58f;  
    padding: 25px;
    border-radius: 10px;

    @media (max-width: 768px) {
      font-size: 24px;
      width: 100%;
    }



  }
  .event-card:hover {  background-color: #eefbf2;  }

  .event-date {

    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #58a58f;
    float: left;
    width: 20%;
    height: 100%;
    text-align: center;
    border-right: 1px solid #58a58f;  
    @media (max-width: 768px) {
      font-size: 15px;
      width: 100%;

    }
  }
 
  .event-date-h1{
    padding-top: 5px;
    width: 100%;
    font-size: 50px;
    text-align: center;
    font-weight: 500;
  }
  .event-info {
    width: 75%;
    float: left;
    padding-left:10px;
    @media (max-width: 768px) {
      width: 100%;
      padding-left: 0px;

    }  }
  
  .event-Title {
    float: left;
    margin-top:5px;
    font-weight: bold;
    font-size: 23px;
    text-align: left;  
    padding: 10px;
    width: 100%;
    color:#58a58f; 

     @media (max-width: 768px) {
      width: 100%;
      text-align: center;  

    } 
  }
 
  .event-Details {
    float: left;
    width: 100%;
 
  }

 
.event-Time{
    float: left;
    width: 100%;
    margin-top: 3px;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 0px;
    text-align: left;  
    padding: 10px;
    color: #000000;

   
    @media (max-width: 768px) {
      width: 100%;
    text-align: center;  

    } 
  }
 
  .event-but{
    float: left;
    width: 100%;
    margin-top: 8px;
    padding: 5px;
  }

  .view-details-btn {
    border: 1px solid #58a58f; 
    background-color:  #58a58f; 
    color: #fff;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    margin-top: 0;
    @media (max-width: 1200px) {
      width: 95%;
      margin-left: 2.5%;
      margin-top: 5px;

    } 

  }
  
  .view-details-btn:hover {
    background-color: #1c1c1c;
    color: #fff;
  }
  
  
  
  .view-details-Coupon{
    font-weight: 600;
    border: 1px solid #58a58f; 
    background-color:  #eefbf2;
    color: #58a58f; 
    border-radius: 4px;
    padding: 8px 8px;
    font-size:15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    margin-top: 0;
    @media (max-width: 1200px) {
      width: 95%;
      margin-left: 2.5%;
      margin-top: 5px;
    } 

  }


  .view-details-Coupon:hover {
    background-color: #000000;
    color:#ffffff 
  }
 