/* WrongAnswers.css */

.exam-submissions-container {
    width: 80%;
    float: left;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 100px;
    margin-left: 10%;
    color: #d1ae20;

    @media (max-width: 768px) {
        width: 95%;
        margin-left: 2.5%;

    }
}

.exam-submissions-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #d1ae20;

    @media (max-width: 768px) {
        font-size: 18px;


    }
}

.exam-submissions-container p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
}

.exam-submissions-container .wrong-answers-list {
    list-style-type: none;
    padding: 0;
}

.exam-submissions-container .wrong-answers-list li {
    margin-bottom: 30px;
    padding: 15px;
    border: 1px solid #d1ae20;
    border-radius: 5px;
    background-color: #fff;
    float: left;
    width: 100%;
}

.exam-submissions-container .question-content {
    font-weight: bold;
    color: #333;
    width: 100%;
    float: left;
    text-align: center;
    border: 1px solid #d1ae20;
    padding: 20px;
    border-radius: 10px;
}

.exam-submissions-container .option {
    margin-top: 10px;
    color: #777;
}

.exam-submissions-container .correct-option {
    margin-right: 15px;
    float: left;
    font-weight: 700;
    margin-top: 0px;
    border-radius: 50px;
    padding: 10px 15px 10px 15px;
    background: #58a58f;
    color: #fff;
}

.Button {
    margin: auto;
    width: 20%;
    padding: 10px 20px 10px 20px;
    background: #d1ae20;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    margin-left: 40%;
    border-radius: 15PX;

    @media (max-width: 768px) {
        font-size: 15px;

    }
}



.LI {
    float: left;
    color: #fff;
    display: list-item;
    width: 49%;
    margin-left: 1%;
    text-align: -webkit-match-parent;
    list-style-type: none;
    border: 1px solid #d1ae20;
    padding: 10px 0px 0px 10px;
    border-radius: 50px;

    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0%;

    }
}

.SPan {
    margin-right: 15px;
    float: left;
    font-weight: 700;
    margin-top: 0px;
    border-radius: 50px;
    padding: 10px 15px 10px 15px;
    background: #d1ae20;
    color: #fff !important;
}



.samp-option {
    float: left;
    margin-top: 13px;
    font-weight: 500;

}