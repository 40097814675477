.room-container {
    position: relative;
    padding-bottom: 50px;
    width: 100%;
    height: 100vh;
  }
  
  .meeting-container {
    width: 100%;
    height: 100vh;
  }
  
  
  .mobile-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }
  
  .mobile-layout .zego-video-container {
    order: 1;
    width: 100%;
    height: 60%; 
    background-color: #f0f0f0;  
  }
  
  .mobile-layout .zego-controls-container {
    order: 2;
    width: 100%;
    height: 40%; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }
  


 
  .zzHlKp_IVmgXBPV3Jv58 {
      position: relative;
      display: flex ;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-width: 960px;
      height: 100%;
      min-height: 530px;
  }

 
 
 
@media (max-width: 768px) {
    .room-container {
        position: relative;
        padding-bottom:  50px;
        width: 100%;
        height: 100vh;
      }
      .meeting-container {
        width: 100%;
        height:100vh;
      }
    
    .D9WLyEQaARfWqCTyVrpU{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: flex-start;
        height: 100%;
        max-width: 100%!important;
        min-width: 0px!important;
      }

      .zzHlKp_IVmgXBPV3Jv58 {
        position: relative;
        display: flex ;
        flex-direction: column;
        /* align-items: center; */
        /* justify-content: center; */
        max-width: 100%!important;
        min-width: 0px!important;
        height: 100%;
        min-height: 530px;
        padding: 0;
     

      }

      .QajuHzlZleFtiTUp_E8u {
        max-width: 100%;
        padding: 0px;

      }

  
      .QajuHzlZleFtiTUp_E8u .sJcgXLqA2Va2eqymgksg {
        max-width:100%!important;
        border-radius: 12px;
        width:100%!important;
    }


  .sJcgXLqA2Va2eqymgksg {
        max-width: 100%!important;
        min-width: 0px!important;
        border-radius: 12px;
 
    }
   ._M8cCug8H18ALQ05cNMt{
    text-align: center;
    width: 100%;
    padding: 8px;
   }

   .TYiiRFB3EhYJGVPE4k4q {
    max-width: 100%!important;
    min-width: 0px!important;
 
}
 .QajuHzlZleFtiTUp_E8u .TYiiRFB3EhYJGVPE4k4q {
    width: 100%;
    padding: 10px 15px;
    font-size: 13px;
    line-height: 18px;
    border: 1px solid #333;
    border-radius: 8px;
    background-color: #fff;
}
.QajuHzlZleFtiTUp_E8u .b1CJxMvQdas9l4lGoyfA {
    position: relative;
    max-width: 100%!important;
    min-width: 0px!important;
    margin-top: 10px;
    
}

.VsTVUAD89KWleD0YRVsD {
     
    align-items: center;
    justify-content: center;
    max-width: 100%!important;
    min-width: 0px!important;
    height: 46px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 46px;
    text-align: center;
    background: #05f;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    margin: auto;
}
.Navbar{
    display: none!important;
    
    }

 
 
  }
 