.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0 20px;
  padding: 20px;
  /* Add padding to improve spacing on small screens */

  @media (max-width: 785px) {
    margin: 0;
    height: auto;

    /* Ensure height is flexible on smaller screens */
    padding: 10px;
    /* Smaller padding on mobile */

  }

  @media (max-width: 480px) {
    padding: 5px;
    /* Even smaller padding for very small screens */
  }
}

.success-h1 {
  color: #58a58f;
  font-size: 2.5rem;
  margin-bottom: 10px;
  animation: fadeInDown 1s ease-in-out;

  @media (max-width: 785px) {
    font-size: 2rem;
    /* Reduce font size on smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 1.75rem;
    /* Further reduce font size on very small screens */
  }
}

.Cancel-h1 {
  color: #dc3545;
  font-size: 2.5rem;
  margin-bottom: 10px;
  animation: fadeInDown 1s ease-in-out;

  @media (max-width: 785px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
}

h2 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-in-out;

  @media (max-width: 785px) {
    font-size: 1.25rem;
    /* Reduce font size on smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    /* Further reduce font size on very small screens */
  }
}

/* Keyframes */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}