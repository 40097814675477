 .Views {
   float: left;
   margin-top: 15px;
   margin-bottom: 50px;
   width: 100%;

 }

 .Views_card {
   float: left;
   padding: 5px 8px 0px 5px;
   background-color: rgb(255, 255, 255);
   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
   border-radius: 50px;
   padding-right: 20px;
   font-size: 23px;
   border: 1px solid #58a58f;

 }

 .Views_icon {
   float: left;
   font-size: 30px;
   padding: 0px 10px 0px 5px;
   border-radius: 50px;
   margin-top: 0px;
   color: #58a58f;
 }