.flex_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}



.flex_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  margin-top: 30px;
}

.SigRow {
  margin-top: 0px;
  float: left;
  width: 100%;
}

.SigRow1 {
  margin-top: 15px;
  padding-bottom: 30px;
  float: left;
  width: 100%;
}


.SigRowImg {
  width: 100%;
  border: 1px solid #58a58f;
  border-radius: 5px;
}

.SigRowTitle {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
  margin-bottom: .75rem;
  font-size: 1.5rem;
}

.Cont_but {
  float: left;
  margin-top: 30px;
  width: 100%;


}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-bottom: 100px;

}

.card_welcome {
  letter-spacing: 2px;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
  margin-bottom: .75rem;
  font-size: 2rem;
}

.card-body_p {
  letter-spacing: 2px;
  font-size: 1.2rem;
  color: #58a58f;
  font-weight: 200;
  margin-bottom: 20px;
}

.card-body_text {
  font-size: 1rem;
  color: #777;
  margin: 10px;
  letter-spacing: 2px;

}

.btn-wrap {
  width: 40%;
}

.WRAP {
  float: left;
  width: 100%;
  margin-bottom: 20px;

}

.BTN {
  float: left;
  text-decoration: none;
  font-size: 1.3rem;
  color: #fff;
  width: 100%;
  margin: 0 10px;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  padding: 10px;
  letter-spacing: 2px;

}

.btn-register {
  background-color: #58a58f;
}

.btn-register:hover {
  background-color: #000;
}

.btn-login {
  background-color: #58a58f;
}

.btn-login:hover {
  background-color: #000;
}


@media (max-width: 600px) {
  .flex_center {
    width: 90%;
    margin-top: 70px;
  }

  .btn-wrap {
    width: 80%;
  }

  .SigRowTitle {
    font-size: 1rem;
  }

  .card_welcome {
    font-size: 1.1rem;
  }

  .card-body_text {
    font-size: .8rem;
  }

  .card-body_p {
    font-size: 1rem;
  }

  .BTN {
    float: left;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px;
    letter-spacing: 1px;

  }
}