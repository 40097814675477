 .CourseCard {
   margin-top: 30px;
   float: left;
   width: 70%;
   margin-left: 20%;
   background-color: #ffffff;
   /* Adjust color as needed */
   border-radius: 5px;
   /* Adjust border radius as needed */
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
   /* Adjust shadow as needed */
   padding: 5px;
   border: 1px solid #58a58f;
 }

 .Course_card_content {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   height: 100%;
   padding: 8px 16px;
   /* Adjust padding as needed */

   border-radius: 8px;
   /* Adjust border radius as needed */
 }

 .Course_card_info {
   margin-right: 8px;
   /* Adjust margin as needed */
 }

 .Course_card_title {
   font-weight: bold;
   color: #4B5563;
   /* Adjust color as needed */
   margin-bottom: 4px;
   /* Adjust margin as needed */
 }

 .Course_card_amount {
   font-size: 16px;
   /* Adjust font size as needed */
   color: #111827;
   /* Adjust color as needed */
 }

 .Course_card_icon {
   fill: #4B5563;
   /* Adjust color as needed */
 }


 .Open_button {

   background-color: #58a58f;
   border: 1px solid #58a58f;
   color: white;
   padding: 10px 24px;
   cursor: pointer;
   float: right;
   border-radius: 5px;
 }

 .Open_button:hover {
   background-color: #010a01;
 }


 .form {
   display: flex;
   align-items: center;
   justify-content: center;

 }

 .Action-Box {
   margin-top: -7px;
   text-align: center;
   padding: 15px 8px;
   border: 2px solid #ccc;
   border-radius: 5px;
   background-color: #eefbf2;
   font-size: 16px;
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
   cursor: pointer;
   margin-left: 20px;
 }

 .Action-Box:focus {
   outline: none;
   border-color: #09a59a;
 }


 .Action-Box option {
   font-size: 16px;
 }

 .Action-Box option:first-child {
   color: #999;
 }

 .Action-Box option:not(:first-child) {
   color: #000;
 }

 .Action-Box option:not(:first-child) {
   color: #000;
 }


 .chip {
   display: inline-block;
   padding: 0 25px;
   height: 50px;
   font-size: 16px;
   line-height: 50px;
   border-radius: 25px;
   background-color: #f1f1f1;
 }

 .chip img {
   float: left;
   margin: 0 10px 0 -25px;
   height: 50px;
   width: 50px;
   border-radius: 50%;
 }