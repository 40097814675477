.W_section_DownTimer {
  float: left;
  padding-top: 0px;
  padding-bottom: 10px;
  width: 50%;
  margin-left: 28%;

  @media (max-width: 1200px) {
    width: 90%;
    padding-top: 10px;
    margin-left: 5%;

  }

}

.W_Room_li .W_container_DownTimer {
  width: 100%;

}

.W_row_DownTimer {

  @media (max-width: 1200px) {}
}

.W_col_DownTimer {

  @media (max-width: 1200px) {
    width: 100%;

  }
}


.W_col_Timer_image-DownTimer {

  width: 100%;

  @media (max-width: 1200px) {
    margin-left: 10%;
    width: 80%;

  }
}



.W_content_wrap_DownTimer {


  @media (max-width: 1200px) {
    width: 99%;
    margin: auto;
    padding-top: 50px;

  }


}

.W_section_heading_wrap_DownTimer {
  margin-bottom: 40px;

}


.W_heading_text_DownTimer {
  margin-top: 15px;
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 24px;
  font-weight: 700;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;

  @media (max-width: 1200) {
    font-size: 15px;
    margin-top: 0px;
    line-height: 30px;
    margin-bottom: 8px;
    text-align: center;
  }
}


.W_heading_description_DownTimer {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px;

  @media (max-width: 1200px) {
    font-size: 12px;
    text-align: center;
    padding: 10px;
  }

}


.W_deals_countdown_DownTimer {
  display: flex;
  text-align: center;
  border-radius: 8px;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 0px;
    border-radius: 5px;
    width: 95%;
    margin: auto;
  }

}







ul {
  list-style-type: none;
  margin: auto;
}



.W_Room_li {
  list-style-type: none;
  justify-self: center;
  padding: 5px;
  margin-top: 0;
  margin: auto;

  @media (max-width: 1200px) {
    padding: 0px;
    margin-top: 9px;

  }
}


.W_dash {
  align-items: center;
  font-size: 16px;
  margin-top: -24px;
  padding: 0;

  @media (max-width: 1200px) {
    padding: 0px;
    font-size: 13px;
    margin-top: 8px;

  }

}



.W_unordered_list {
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

}



.W_countdown_DownTimer {
  width: 70%;
  text-align: center;
  justify-content: center;
  border: 1px solid #1f1d0d;
  border-radius: 8px;

  @media (max-width: 1200px) {
    padding: 0px;
    width: 100%;


  }

}


.W_countdown_DownTimer strong {
  display: block;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;

  @media (max-width: 1200px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

}

b,
strong {
  font-weight: bolder;
}



.W_discount_value {
  padding: 10px;
  width: 25%;
  height: 110px;
  margin-left: 28px;
  color: #ffffff;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: #58a58f;

  @media (max-width: 1200px) {
    margin-top: 0;
    margin-left: 8px;
    width: 25%;
    padding: 0px;
    height: 65px;


  }


}

.W_Room {
  @media (max-width: 1200px) {
    font-size: 10px;
    margin-top: -4px;
  }
}

.W_discount_value strong {
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;

  @media (max-width: 1200px) {
    font-size: 15px;
    font-weight: 500;
    line-height: 40px;
    ;

  }


}




.W_btn {
  margin-top: 25px;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 8px;
  display: inline-block;
  font-weight: 700;
  line-height: 3;
  color: #f7f7f7;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #0a0101;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-left: 0%;

  @media (max-width: 1200px) {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;

  }
}



.W_P {
  padding-top: -5px;
  font-size: 1rem;
  text-align: center !important;
  font-weight: 100 !important;
  width: 100%;
  margin-left: 8px;
  color: #000000;
  padding-bottom: 0px;

  @media (max-width: 1200px) {
    font-size: .7rem;
    margin-top: 0px;
    margin-left: 0px;


  }
}