.landing-one-section_DownTimer {
  float: left;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 40%;
  margin-left: 30%;
  @media (max-width: 1200px) {
    width: 100%;
    padding-top: 10px;
    margin-left: 0%;

  }

}


.landing-one-container_DownTimer {
  width: 100%;
  margin: 0;
}

.landing-one-row_DownTimer {

  @media (max-width: 1200px) {}
}

.landing-one-col_DownTimer {

  @media (max-width: 1200px) {
    width: 100%;

  }
}


.landing-one-col_Timer_image-DownTimer {
  width: 100%;
  margin-left:0;
  @media (max-width: 1200px) {
    margin-left: 10%;
    width: 80%;

  }
}

.landing-one-image_widget_DownTimer {
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  box-shadow: -20px 20px 0 0 #58a58f;

  @media (max-width: 768px) {
    box-shadow: -10px 10px 0 0 #58a58f;

  }

}

.landing-one-content_wrap_DownTimer {

  @media (max-width: 1200px) {
    width: 99%;
    margin: auto;
    padding-top: 50px;

  }


}

.landing-one-section_heading_wrap_DownTimer {
  margin-bottom: 40px;
}


.landing-one-heading_text_DownTimer {
  margin-top: 15px;
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 24px;
  font-weight: 700;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;

  @media (max-width: 1200) {
    font-size: 15px;
    margin-top: 0px;
    line-height: 30px;
    margin-bottom: 8px;
    text-align: center;
  }
}


.landing-one-heading_description_DownTimer {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px;
  width: 100%;

  @media (max-width: 1200px) {
    font-size: 12px;
    text-align: center;
    padding: 10px;
  }

}


.landing-one-deals_countdown_DownTimer {
  display: flex;
  text-align: center;
  border-radius: 8px;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 0px;
    border-radius: 5px;
    width: 95%;
    margin: auto;
  }

}

ul {
  list-style-type: none;

}

li {
  list-style-type: none;
  margin: auto;
  justify-self: center;
  padding: 13px;

  @media (max-width: 1200px) {
    padding: 0px;
    font-size: 10px;

  }
}


.landing-one-dash {
  align-items: center;
  font-size: 16px;
  margin-top: -24px;
  padding: 0;

  @media (max-width: 1200px) {
    padding: 0px;
    font-size: 13px;

  }

}

.landing-one-UNOrdered_list {
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

}


.landing-one-COUntdown_DownTimer {
  padding: 9px 10px;
  text-align: center;
  justify-content: center;
  border: 1px solid #1f1d0d;
  border-radius: 8px;

  @media (max-width: 1200px) {
    padding: 3px;
    /* width: 100%; */
  }

}

.landing-one-COUntdown_DownTimer strong {
  display: block;
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;

  @media (max-width: 1200px) {
    font-size: 17px;
    font-weight: 600;
    line-height: 30px;

  }

}

b,
strong {
  font-weight: bolder;
}

.landing-one-span_Days {
  font-size: 20px;

  @media (max-width: 1200px) {
    font-size: 13px;
  }
}


.landing-one-discount_Value {
  padding: 10px;
  width: 30%;
  height: 110px;
  margin-left: 28px;
  color: #1f1d0d;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: #58a58f;
  color: #fff;

  @media (max-width: 1200px) {
    margin-left: 8px;
    width: 22%;
    height: 70px;

  }


}


.landing-one-discount_Value strong {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;

  @media (max-width: 1200px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;


  }


}




.landing-one-btN {
  margin-top: 25px;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 8px;
  display: inline-block;
  font-weight: 700;
  line-height: 3;
  color: #f7f7f7;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #000000;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-left: 0%;

  @media (max-width: 1200px) {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;

  }
}



.landing-one-P {
  padding-top: 5px;
  font-size: 1rem;
  text-align: center !important;
  font-weight: 100 !important;
  width: 90%;
  margin-left: 8px;
  color: #000000;
  padding-bottom: 0px;

  @media (max-width: 1200px) {
    font-size: .7rem;
    margin-top: 0px;
    margin-left: 0px;


  }
}