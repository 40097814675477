 .text-gradient-secondary {
   background-image: linear-gradient(100deg, #b88723, #f1f0ef);
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-size: 30px;
   font-weight: 700;

   @media (max-width: 1200px) {
     font-size: 15px;
     line-height: 2;
     /* Adjust as needed */

   }

   @media (max-width: 768px) {

     font-size: 13px;

   }
 }

 .gradient-underline-thin {
   background: linear-gradient(135deg, #548f8f, #f1f0ef);
   background-position: 0 100%;
   background-size: 100% 2px;
   background-repeat: repeat-x;
   padding-bottom: 7px;
   margin-left: 0px;
   color: #fff;
   line-height: 2rem;
   font-size: 19px;


   @media (max-width: 1200px) {
     font-size: 15px;
     line-height: 2;

   }

   @media (max-width: 768px) {

     font-size: 13px;

   }

 }



 .text-center {
   text-align: center;
   /* Center align text */
 }

 /* Button styles */
 .btn {
   width: 100%;
   border-radius: 5px;
   /* Rounded corners for buttons */
   padding: 10px 20px;
   /* Add padding inside buttons */
   margin: 10px;
   /* Add margin around buttons */
   font-size: 1.2rem;
   /* Increase font size */
 }

 .btn-primary {
   background-color: #007bff;
   /* Primary button color */
   border: none;
   /* Remove button border */
 }

 .btn-primary:hover {
   background-color: #0056b3;
   /* Darker color on hover */
 }

 /* Call to action buttons */
 .call-action {
   background-color: #6eb5a8;
   /* Green color for call to action */
 }

 .call-waiting {
   background-color: #dc3545;
   /* Red color for call waiting */
 }

 /* Login link */
 .login {
   font-size: 1.1rem;
   /* Adjust font size */
 }

 .title {
   text-align: center;
   font-size: 2.5rem;
   text-align: center !important;
   margin-bottom: 1rem !important;
   font-weight: 700 !important;
   padding-top: 20px;
   line-height: 1.2;
   color: #09a59a;
   width: 100%;

 }




 .row {

   width: 80%;
   margin: 0 auto;
   padding: 50px 50px;
 }




 .row {

   width: 80%;
   margin: 0 auto;
   padding: 50px 50px;
 }



 .pm {
   text-align: right;
   font-size: 20px;
   margin-top: 0;
   color: #fff;
   width: 80%;
   margin: 0 auto;
   font-family: GE_SS_TWO;
   font-weight: 400;
   /* line-height: 1.5; */
 }

 .Samp {
   float: right;
   padding: 1rem;
   color: #fff;

 }







 .row {
   display: flex;
   flex-wrap: wrap;
   width: 90%;
   margin: auto;

 }


 .col-md-3 {
   flex: 0 0 25%;
   max-width: 25%;
   text-align: center;

 }

 .count {

   font-size: 2.5rem;
   text-align: center !important;
   margin-bottom: 1rem !important;
   font-weight: 700 !important;
   padding-top: 20px;
   line-height: 1.2;
   color: #09a59a;


 }

 .h4_ar {
   width: 300px;
   float: right;
   font-size: 18px;
   font-weight: 700 !important;
   text-align: right;

   @media (max-width: 1200px) {
     font-size: 14px;
     line-height: 2;
     /* Adjust as needed */

   }

   @media (max-width: 768px) {
     /* Tablet styles */
     font-size: 14px;

   }
 }

 .h4_en {
   width: 300px;
   float: left;
   font: size 18px !important;
   font-weight: 700 !important;
   text-align: left;

   @media (max-width: 1200px) {
     font-size: 16px;
     line-height: 2;
     /* Adjust as needed */

   }

   @media (max-width: 768px) {
     /* Tablet styles */
     font-size: 13px;

   }
 }








 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6 {
   margin-bottom: 0.5rem;
   font-weight: 400;
   line-height: 1.2;
 }