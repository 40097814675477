 .accordion {
   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
   padding: 5px;
   width: 100%;
   margin: auto;
   margin-top: 25px;
   color: #ddd;

   @media (max-width: 768px) {
     width: 100%;
   }
 }

 .accordion-item {
   border: 1px solid #ddd;
   margin-bottom: 5px;
   border-radius: 5px;

   &:hover {
     /* border: 1px solid #666ee8;   */
     border-radius: 8px;
   }

 }

 .accordion-title {
   border-radius: 5px;
   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
   padding: 5px;
   cursor: pointer;
   background-color: #fff;
   font-size: 17px;
   font-weight: 500;
   color: #212832;
   line-height: 1.5;
   font-family: 'DGBaysan', sans-serif;
   transition: 0.6s all cubic-bezier(0, 1, 0, 1);

 }

 .des {
   font-size: 12px;
   padding-left: 10px;
   color: #333;
   line-height: 1.5;
   margin-top: -18px;

 }


 .Title {
   /* padding:  5px; */
   padding-left: 10px;
   font-weight: 500;
   font-size: 18px;


 }

 @media (max-width: 768px) {
   .accordion-title {
     padding: 5px;
     font-size: 10px;

   }

   .title {
     padding: 5px;
     padding-left: 5px;
     font-size: 12px;

   }
 }


 .accordion-title.active {
   border: 1px solid #ddd;
   color: #58a58f;
   background-color: #eff1f6;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   padding-bottom: 18px;
   border-radius: 6px 6px 0 0;
 }

 .accordion-content {
   padding: 10px;
   transition: 0.6s all cubic-bezier(1, 0, 1, 0);
   background-color: #fff;
 }


 .Fa {
   color: #333;
   font-size: 18px;
   padding: 1px;
   margin-top: 40px;
 }

 @media (max-width: 768px) {
   .Fa {
     color: #333;
     font-size: 13px;
     padding: 1px;
     margin-top: 18px;
   }

 }

 .Quizcard {
   width: 100%;
   margin: auto;
   margin-top: 15px;
   color: #ddd;
   background-color: #fff;
   display: flex;
   justify-content: space-between;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   padding: 0px;
   border-radius: 5px;


 }

 .image-container {
   flex: 0 0 auto;
   margin-right: 15px;
 }

 .text-container {
   flex: 1;
 }

 .Quizcard img {
   height: 80px;
   width: auto;
   border-radius: 5px 0px 0px 5px;

   @media (max-width: 768px) {

     height: 70px;

   }



 }

 .quiz-heading {
   font-size: 11px;
   font-weight: bold;
   color: #58a58f;
   text-transform: uppercase;
   margin: 0px;
   margin-top: 8px;

   @media (max-width: 768px) {
     font-size: 8px;
     margin-top: 10px;
   }

 }

 .Quizcard h3 {
   font-size: 1.1rem;
   margin: 0;
   color: #000;
   margin-top: 3px;

   @media (max-width: 768px) {
     font-size: 10px;
     margin-top: 5px;

   }



 }

 .Quizcard p {
   margin: 0;
   color: #000;
   font-size: 14px;
   margin-top: 5px;
   padding: 0px;

   @media (max-width: 768px) {
     font-size: 7px;
     padding: 5px;
     margin-top: 0px;

   }


 }

 .Quizcard button {
   background-color: #58a58f;
   color: #fff;
   border: none;
   border-radius: 3px;
   padding: 10px 20px;
   margin-top: 30px;
   margin-right: 20px;
   cursor: pointer;

   @media (max-width: 768px) {
     font-size: 10px;
     margin-right: 18px;
     padding: 7px 10px;

   }




 }