.Run_button {
    background-color: #58a58f;
    border: 1px solid #58a58f;
    color: white;
    cursor: pointer;
    float: left;
    border-radius: 5px;
    width: 150px;
    height: 48px;
    margin-top: -5px;
    padding-top: 0;
    margin-left: 15px;
}

.Stop_button {
    background-color: #cd2121;
    border: 1px solid #a55858;
    color: white;
    float: left;
    border-radius: 5px;
    width: 150px;
    height: 48px;
    margin-top: -5px;
    padding-top: 0;
    margin-left: 15px;
}

.Run_button:hover {
    background-color: #000000;
}

.Stop_button:hover {
    background-color: #000000;
}


/* Common styles for the container */
.massage_container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100px; */
    font-family: Arial, sans-serif;
    text-align: center;
    width: 100%;
    padding: 15px;
}

.Load {
    width: 100px;
    float: left;
    margin-left: 5px;
}

/* Loading styles */
.loading {
    float: left;
    font-size: 1.5rem;
    color: #555;
    position: relative;

}

.spinner {
    float: left;
    width: 24px;
    height: 24px;
    border: 4px solid #ccc;
    border-top-color: #58a58f;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 0px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Error styles */
.ERror {
    color: red;
    background-color: #ffe6e6;
    padding: 10px;
    border: 1px solid red;
    border-radius: 4px;
    font-size: 1.2rem;
    width: 100%;
    margin-left: 0;
}

.customers {
    width: 80px;
}