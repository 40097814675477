.product {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 480px;
	height: 150px;
	background-color: rgba(0, 0, 0, 0.1);
	margin-top: 50px;
	margin-left: 50px;
	margin-bottom: 10px;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.product img {
	width: 200px;
	height: 150px;
}

.description h3 {
	font-size: 24px;
	padding-right: 20px;
}

.description h5 {
	font-size: 18px;
	font-size: 200;
}

.button_free {
	font-size: 18px;
	padding: 10px;
	background-color: #58a58f;
	color: white;
	border: none;
	margin-left: 0px;
	width: 100%;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
	border-radius: 3px;
}

.button_free:hover {
	cursor: pointer;
	opacity: 0.9;
	background-color: #000000;

}