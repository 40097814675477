.review-container {
  width: 100%;
  margin: auto;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    padding: 0px;

  }
}

h2 {
  font-size: 25px;
  font-weight: 500;
  color: #212832;
  font-family: "Gordita";
  margin-bottom: 5px;
  margin-top: -5px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;

  }
}


.rating-section {
  width: 850px !important;
  margin-bottom: 20px;
  margin: auto;

  @media (max-width: 768px) {
    width: 250px !important;
    margin: auto;
    margin-bottom: 10px;
    padding: 0px;
  }
}


.star-container {
  width: 62px !important;
  margin: auto !important;
  padding-left: 2.5px !important;
  padding-right: 2.5px !important;

  @media (max-width: 768px) {
    width: 50px !important;
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;

  }

}

.widget-svg {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 50px !important;
  margin: 0 !important;

}

.star {
  width: 10px !important;
}



.custom-star-ratings {
  width: 10px !important;
  font-size: 5px !important;

  @media (max-width: 768px) {
    width: 5px !important;
    font-size: 2px !important;

  }
}


.star-ratings {
  width: 850px !important;
  margin: auto !important;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.widget-svgwidth {
  width: 10px !important;
  height: 10px !important;
}

.custom-star-ratings {
  width: 850px !important;
  margin: auto !important;

  @media (max-width: 768px) {
    width: 100% !important;

  }
}

/* Adjust font size for smaller screens using media query */
@media screen and (max-width: 768px) {
  .custom-star-ratings {
    font-size: 18px;
  }
}


.comment-section {
  margin-bottom: 15px;
}


textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;

  @media (max-width: 768px) {
    padding: 8px;
  }
}

.Submit-button {
  background-color: #58a58f;
  color: #fff;
  padding: 12px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 17px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px 10px;
    font-size: 15px;
  }
}

.Submit-button:hover {
  background-color: #58a58f;
}

.Success-message {
  background-color: #58a58f;
  /* Bootstrap's success background color */
  border: 1px solid #d0e9c6;
  /* Bootstrap's success border color */
  color: #fff;
  /* Bootstrap's success text color */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}