.counter-container {
  display: flex;
  align-items: center;

}

.counter {
  width: 100%;

}

.counter-button {
  width: 25%;
  background-color: #58a58f;
  color: #fff;
  border: none;
  padding: 3px 5px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.counter-button:hover {
  background-color: #000000;
}

.counter-value {
  float: right;
  color: #58a58f;
  font-size: 20px;
  /* margin: 0 10px; */
  /* padding: 5px; */

}