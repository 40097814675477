.cardArea {
  width: 80%;
  margin-top: 0px;
  margin-bottom: 30px;

  align-items: center;
  padding-top: 10px;
  flex-wrap: wrap;
  margin-left: 10%;
  height: auto;
  display: flex;
  justify-content: space-around;

  @media (max-width: 768px) {
    width: 90%;

    margin-left: 5%;
    margin-top: 0px;
    display: block;

  }


}

.SectionTitle {
  width: 100%;
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
  color: #58a58f;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 25px;
  }

}

.card {
  width: 40%;
  float: left;
  border: 1px solid #58a58f;
  border-radius: 8px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 15px;
  }
}


.bannerTitle {
  font-size: 26px;
  margin-bottom: 30px;
  font-family: Hind, sans-serif;
  color: #0e1133;
  margin-top: 0;
  line-height: 1.2;
  transition: all .3s ease-out 0s;


}



.bannerTtem {

  position: relative;
  margin-bottom: 40px;
  height: 230px;
  padding: 50px 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;



}



.E-btn {
  text-decoration: none;
  color: inherit;
  outline: none;
  border: none;
  color: #fff;

}

.bannerContent {
  position: relative;
  z-index: 1;
  float: left;
}

.bannerThumb img {
  width: 230px;

  display: block;

  @media (max-width: 768px) {
    display: none;
  }


}

.bannerItem {
  padding: 50px 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;

}




.E-btn {
  display: inline-block;
  padding: 0px;
  line-height: 40px;
  text-align: center;
  padding: 0 25px;
  color: #fff;
  background: #58a58f;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;

}

.bannerItem span {
  border: 1px solid #58a58f;
  font-size: 14px;
  font-weight: 500;
  color: #58a58f;
  background: #fff;
  display: inline-block;
  line-height: 22px;
  padding: 0 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  text-transform: capitalize;
}