 .OfferMessage {
     position: relative;
     background-color: #58a58f;
     color: #fff;
     padding: 8px 0px;
     font-size: 20px;
     text-align: center;
     font-weight: 100;
     width: 95%;
     margin-left: 2.5%;
     letter-spacing: 2px;
     animation: pulse 2.5s infinite;
     z-index: 100;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

     @media (max-width: 768px) {
         margin-top: 40px;
         position: fixed;
         font-size: 15px !important;
         padding: 8px 0px;

     }
 }

 /* Animation for a pulsing effect */
 @keyframes pulse {
     0% {
         transform: scale(1);
     }

     50% {
         transform: scale(1.05);
     }

     100% {
         transform: scale(1);
     }
 }