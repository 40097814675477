.Event_area {
  float: left;
  width: 90%;
  margin-left: 5%;

  margin-top: 50px;
  padding-bottom: 20px;

  @media (max-width: 1200px) {
    width: 90%;
    margin-left: 5%;
  }


}


.Event_widget-containe {
  position: relative;
  padding: 0;
  margin: 0;
  float: left;
  width: 80%;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    width: 100%;
    display: block;

  }


}

.Even-widget-heading {
  float: left;
  width: 100%;

}



.Event_heading-Title {
  padding-top: 20px;
  text-align: left;
  width: 100%;
  font-size: 35px;
  padding: 0;
  margin: 0;
  color: rgb(88, 165, 143);


  @media (max-width: 768px) {
    font-size: 22px;

  }
}



.Event_widget-p {
  padding-top: 5px;
  width: 100%;
  text-align: left;
  font-size: 15px;
  color: #696969;
  margin-bottom: 0;

}


.Event_widget_BUT {
  float: left;
  width: 15%;
  margin-bottom: 10px;
  margin-top: 15px;
  margin-left: 50px;

  @media (max-width: 1200px) {
    float: right;
    width: 100%;
    margin-bottom: 13px;
    margin-top: 0px;
    margin-left: 0px;

  }
}

.Even_widget_btn {

  width: 180px;
  text-decoration: none;
  color: inherit;
  outline: none;
  border: none;
  display: inline-block;
  padding: 0px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background: #58a58f;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;

  @media (max-width: 1200px) {
    font-size: 11px;
    width: 110px;
    line-height: 30px;

  }

}

.No_available {
  float: left;
  width: 100%;
  color: #58a58f;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  font-size: 20px;
  border: 1px solid rgba(48, 146, 85, 0.2);
}