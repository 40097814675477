 .list-border-check {
   width: 100%;

 }

 ul {
   float: left;
   width: 100%;
   display: block;
   list-style-type: disc;
   margin-bottom: 15px;

 }

 .list-border-check li {
   float: left;
   width: 48%;
   margin-bottom: 0.625rem;
   font-size: 20px;
   margin-left: 2%;

   @media (max-width: 1200px) {

     width: 100%;
     font-size: 13px;
     text-align: center;

   }
 }

 .Check {
   margin-right: 10px;
   color: #58a58f;

 }