 .phone-div {
   width: 500px;
   margin: 20px auto;
   padding: 20px;
   background-color: #f9f9f9;
   border: 1px solid #ddd;
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

 }

 @media (max-width: 768px) {
   .phone-div {
     width: 98%;
     padding: 10px;
   }
 }

 .phone-form {
   width: 100%;
 }

 .form-group {
   margin-bottom: 15px;
 }

 .form-label {
   display: block;
   margin-bottom: 15px;
   font-weight: bold;
   color: #333;
   font-size: 15px;
 }

 .phone-input-container {
   width: 400px;
   margin: auto;

 }

 @media (max-width: 768px) {
   .phone-input-container {
     float: left;
     width: 90%;

   }

 }

 .phone-input {
   width: 100%;
   padding: 24px;
   border: 1px solid #58a58f;
   border-radius: 4px;
   font-size: 16px;
   box-sizing: border-box;

 }