 .section_DOWnn {
   float: left;
   width: 100%;

 }


 .deals_countdown_DowN {
   width: 100%;
   float: left;

   @media (max-width: 1200px) {
     padding: 0px;
     border-radius: 5px;
     width: 100%;
     margin: auto;
   }

 }

 ul {
   float: left;

   list-style-type: none;
   width: 100%;

 }

 li {
   list-style-type: none;

   /* padding:10px; */
   @media (max-width: 1200px) {
     /* padding: 5px; */
   }
 }


 .dash {
   align-items: center;
   font-size: 16px;
   /* margin-top: -24px; */
   padding: 0;

   @media (max-width: 1200px) {
     padding: 0px;
     font-size: 13px;
   }

 }

 .unordered_lisT {
   justify-content: flex-start;
   margin: 0;
   padding: 0;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
 }


 .countdown_DowN {
   width: 100%;
   text-align: center;
   justify-content: center;
   border-radius: 8px;

   @media (max-width: 1200px) {
     width: 100%;
   }

 }

 .countdown_DowN strong {
   text-align: center;
   display: block;
   font-size: 27px;
   font-weight: 700;
   /* line-height: 50px; */
   font-family: Barlow, sans-serif;

   @media (max-width: 1200px) {
     font-size: 15px;
     font-weight: 600;
     /* line-height: 30px;  */
   }
 }









 .li {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   width: 18.5%;
 }

 .div-livebutton {
   width: 250px;
   margin: auto;

   @media (max-width: 1200px) {}
 }

 .finished {
   font-weight: 400;
   text-align: center;
   font-size: 35px;
   width: 100%;
   background-color: #58a58f;
   margin-top: 15px;

   color: #ffffff;

   @media (max-width: 1200px) {
     font-size: 25px;




   }
 }

 .EventSpanDays {
   font-size: 25px;
   width: 100%;
   text-align: center;

   @media (max-width: 785px) {
     font-size: 13px;

   }
 }