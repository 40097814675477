.post {
   border: 1px solid rgba(48, 146, 85, 0.2);
   border-radius: 0px;
   padding: 0px;
   margin-bottom: 10px;
   width: 100%;
   margin-left: 0%;

   @media (max-width: 768px) {
      width: 90%;
      margin-left: 5%;
   }


}

.post-header {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
   padding: 10px;
}

.author-image {
   width: 50px;
   height: 50px;
   border-radius: 50%;
   margin-right: 10px;
   padding: 0;
   border: 1px solid rgba(48, 146, 85, 0.2);
}

.author-name {
   font-size: 16px;
   font-weight: 500;
}

.created-at {
   margin-left: auto;
   font-size: 10px;
}

.created-cat {
   margin-left: auto;
   background-color: rgba(48, 146, 85, 0.2);
   color: #58a58f;
   padding: 5px 15px;
   border-radius: 2px;
}

.post-content {
   margin-bottom: 0px;
   padding: 10px;
   padding-top: 0px;
}

.text-content {
   margin-bottom: 0px;
   line-height: 1.8;
}

.image-content {
   width: 100%;
   object-fit: cover;
}

.post-actions {
   background-color: #58a58f;
   color: white;
   font-weight: 500;
   padding: 8px;
   font-size: 18px;
   border: 1px solid #58a58f;
}