.landing-two-section_Timer {
  padding-top: 50px;
  padding-bottom: 0px;
  width: 90%;
  margin: auto;
 
  @media (max-width: 1200px) {
    width: 100%;
    padding-top: 10px;

  }

}


.landing-two-container_Timer {
  width: 100%;
 

}

.landing-two-row_Timer {
  margin: 15px;
  display: flex;
  flex-wrap: wrap;
   

  @media (max-width: 1200px) {
    display: block;
  }
}

.landing-two-Col_Timer {
  flex: 1 0 0%;
  width: 41.66666667%;
  

  @media (max-width: 1200px) {
    width: 100%;

  }
}


.landing-two-col_Timer_image {
  float: left;
  margin-left: 20%;
  width: 70%;
  margin-top: 25px;

  @media (max-width: 1200px) {
    margin-left: 10%;
    width: 80%;
    margin-top: 0px;

  }
}

.landing-two-image_widget_Timer {
  margin-top: 25px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  box-shadow: -20px 20px 0 0 #58a58f;
  

  @media (max-width: 768px) {
    box-shadow: -10px 10px 0 0 #58a58f;
    margin-top: 0px;

  }

}

.landing-two-content_wrap_Timer {
  width: 100%;
  margin-left: 0%;

  @media (max-width: 1200px) {
    width: 99%;
    margin: auto;
    padding-top: 50px;

  }


}

.landing-two-section_heading_wrap_Timer {
  float: left;
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
  width: 100%;


}


.landing-two-heading_text_Timer {
  margin-top: 15px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 8px;
  font-weight: 700;
  color: #1f1d0d;
  font-family: Barlow, sans-serif;

  @media (max-width:785) {
    font-size: 11px;
    margin-top: 0px;
    line-height: 30px;
    margin-bottom: 8px;
    text-align: center;
  }
}


.landing-two-heading_description_Timer {

  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  padding: 0px;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 12px;
    text-align: center;
    padding: 10px;
  }

}


.landing-two-Deals_countdowN_Timer {
  display: flex;
  padding: 9px 10px;
  text-align: center;
  border-radius: 8px;
  width: 100%;
 
  @media (max-width: 1200px) {
    padding: 0px;
    border-radius: 5px;
    width: 96%;
    margin: auto;
  }

}

ul {
  list-style-type: none;
}

.landing-two-li  {
  list-style-type: none;
  margin: auto;
text-align: center;

 
}


.landing-two-DAsh {
  align-items: center;
  font-size: 16px;
  margin-top: -24px;
  padding: 0;

  @media (max-width: 1200px) {
    padding: 0px;
    font-size: 11px;

  }

}

.landing-two-UNordered_List {
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
 
}


.landing-two-CountdowN_TimeR {
  width: 95%;
  text-align: center;
  justify-content: center;
  float: left;
  border: 1px solid #1f1d0d;
  border-radius: 8px;
  background-color: #fff;
 
  @media (max-width: 1200px) {
    padding: 3px;
    width: 90%;


  }

}

.landing-two-CountdowN_TimeR strong {
  display: block;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;
  justify-content: center;
  @media (max-width: 1200px) {
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
  }

}

b,
strong {
  font-weight: bolder;

}

.landing-two-span_Days {
  font-size: 20px;

  @media (max-width: 1200px) {
    font-size: 13px;
  }
}


.landing-two-Discount_Value {
  padding: 10px;
  width: 25%;
  height: 110px;
  margin-left: 28px;
  color: #ffffff;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: #58a58f;
 
  @media (max-width: 1200px) {
    margin-left: 8px;
    width: 28%;
    height: 70px;

  }


}


.landing-two-discount_Value strong {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: Barlow, sans-serif;
 
  @media (max-width: 1200px) {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;

  }


}




.landing-two-btn {
  margin-top: 25px;
  width: 90%;
  border: 2px solid transparent;
  border-radius: 8px;
  display: inline-block;
  font-weight: 700;
  line-height: 3;
  color: #f7f7f7;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #0a0101;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-left: 5%;
  @media (max-width: 1200px) {
    float: left;
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;

  }
}



.landing-two-P {
  padding-top: -5px;
  font-size: 1rem;
  text-align: center !important;
  font-weight: 100 !important;
  width: 100%;
  margin-left: 8px;
  color: #000000;
  padding-bottom: 0px;

  @media (max-width: 1200px) {
    font-size: .7rem;
    margin-left: 0px;


  }
}