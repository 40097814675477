.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 40%; 
    left: 50%;
    transform: translate(-50%, 0); 
    width: 80%;  
    max-width: 600px;
    padding: 20px;
    border: 2px solid rgba(255, 0, 0, 0.8);  
    border-radius: 10px;
    background-color: rgba(255, 230, 230, 0.9); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    text-align: center;
    z-index: 1000;
  }
  
  .error-icon {
    font-size: 50px;  
    color: rgba(255, 0, 0, 0.8); 
    margin-bottom: 10px;
  }
  
  .error-message {
    font-size: 18px;  
    color: #333;  
    line-height: 1.6; 
  }